<template>
    <div>
        <div v-if="singleTrackerSelected">
            <div class="l-padded heading">
                <h3 class="t-title">{{ $t('heading') }}</h3>
            </div>

            <AssetDetailOverview
                :tracker="singleTrackerSelectedDetails || singleTrackerSelected"
                :show-picture="false"
            />

            <EditAssetView
                :id="singleTrackerSelected.id"
                :has-permission="true"
                :shoud-redirect-on-save="false"
                :show-location-edit="false"
                @saved="showNotification"
            />
        </div>

        <div v-else class="l-padded">
            <h3 class="t-title">{{ $t('heading') }}</h3>

            <div class="l-padded-y u-bb">
                <label>{{ $t('selectedAssets') }}</label>

                <div class="type-card-row">
                    <AssetTypeCountCard
                        v-for="(count, assetType) in assetsByType(assetIds)"
                        :key="`asset-card-${assetType}`"
                        :icon="assetTypeIcons[assetType]"
                        :count="count"
                    />
                </div>
            </div>

            <p class="l-padded-y u-bb disclaimer">
                <b>{{ $t('warning') }}</b> {{ $t('disclaimer') }}
            </p>

            <div
                class="l-padded-y l-stack l-gap-2"
                :class="{ 'u-bb': isAdminOrStaff }"
            >
                <div>
                    <div class="form-label">
                        {{ $t('type') }}
                    </div>

                    <AssetTypeSelect v-model="type" />
                </div>

                <div>
                    <div class="form-label">
                        {{ $t('location') }}
                    </div>

                    <LocationSelect
                        v-model="location"
                        :removable="true"
                        :can-be-left-unchanged="true"
                    />
                </div>

                <ColorInput
                    v-model="color"
                    :label="$t('color')"
                    class="color-input-labeled"
                />

                <AxButton
                    :disabled="isLoadingDetails"
                    :loading="isLoadingDetails"
                    @click="saveDetails"
                >
                    {{ $t('saveDetails') }}
                </AxButton>
            </div>

            <div v-if="isAdminOrStaff" class="l-padded-y l-stack l-gap-2">
                <div>
                    <div class="form-label">
                        {{ $t('usersViewingAccess') }}
                    </div>

                    <UserSelect
                        v-model="usersViewingAccess"
                        :exclude="usersEditingAccess"
                        :multiple="true"
                    />
                </div>

                <div>
                    <div class="form-label">
                        {{ $t('usersEditingAccess') }}
                    </div>

                    <UserSelect
                        v-model="usersEditingAccess"
                        :exclude="usersViewingAccess"
                        :multiple="true"
                    />
                </div>

                <AxButton
                    :disabled="isLoadingPermissions"
                    :loading="isLoadingPermissions"
                    @click="savePermissions"
                >
                    {{ $t('savePermissions') }}
                </AxButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import AssetDetailOverview from '../AssetDetailOverview'
import AssetTypeCountCard from '../AssetTypeCountCard'
import AssetTypeSelect from '../AssetTypeSelect'
import AxButton from '../AxButton'
import ColorInput from '../ColorInput'
import EditAssetView from './EditAssetView'
import LocationSelect from '../LocationSelect'
import UserSelect from '../UserSelect'

export default {
    name: 'DashboardAssetsDetailView',
    components: {
        AssetDetailOverview,
        AssetTypeCountCard,
        AssetTypeSelect,
        AxButton,
        ColorInput,
        EditAssetView,
        LocationSelect,
        UserSelect,
    },
    props: {
        assetIds: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            color: '',
            isLoadingDetails: false,
            isLoadingPermissions: false,
            singleTrackerSelectedDetails: null,
            location: 'NOT_SET',
            type: 0,
            usersViewingAccess: [],
            usersEditingAccess: [],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('auth', ['isAdminOrStaff']),
        ...mapGetters('tracker', ['assetsByType', 'assetTypeIcons']),
        singleTrackerSelected() {
            if (this.assetIds.length !== 1) {
                return null
            }

            return this.trackers.find(
                tracker => tracker.asset === this.assetIds[0]
            )
        },
    },
    watch: {
        assetIds() {
            this.location = 'NOT_SET'
        },
        singleTrackerSelected: {
            immediate: true,
            async handler() {
                this.singleTrackerSelectedDetails = null

                if (this.singleTrackerSelected) {
                    const { data } = await httpHelper.get(
                        `/trackers/${this.singleTrackerSelected.id}/`
                    )

                    this.singleTrackerSelectedDetails = data
                }
            },
        },
    },
    methods: {
        ...mapMutations('tracker', ['updateTracker']),
        async saveDetails() {
            this.isLoadingDetails = true

            const { data: updatedTrackers } = await httpHelper.post(
                '/assets-update/',
                {
                    assets: this.assetIds,
                    ...(this.color && { color: this.color }),
                    ...{ location: this.location },
                    ...(this.type && { asset_type: this.type }),
                }
            )

            updatedTrackers.forEach(tracker => this.updateTracker(tracker))

            this.isLoadingDetails = false
            this.showNotification()
        },
        async savePermissions() {
            this.isLoadingPermissions = true

            await httpHelper.post('/assets-update/', {
                assets: this.assetIds,
                read_users: this.usersViewingAccess,
                write_users: this.usersEditingAccess,
            })

            this.isLoadingPermissions = false
            this.showNotification()
        },
        showNotification() {
            this.$notify({
                title: this.$t('successNotificationTitle'),
                text: this.$t('successNotificationText'),
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "color": "Color",
        "disclaimer": "Existing values will be overwritten.",
        "heading": "Edit",
        "location": "Location",
        "saveDetails": "Save details",
        "savePermissions": "Save permissions",
        "selectedAssets": "Selected Assets",
        "successNotificationText": "The assets have been updated.",
        "successNotificationTitle": "Successfully!",
        "type": "Type",
        "usersEditingAccess": "Users with editing access",
        "usersViewingAccess": "Users with viewing access",
        "warning": "Warning."
    },
    "de": {
        "color": "Farbe",
        "disclaimer": "Alte Werte werden überschrieben.",
        "heading": "Bearbeiten",
        "location": "Standort",
        "saveDetails": "Details speichern",
        "savePermissions": "Berechtigungen speichern",
        "selectedAssets": "Ausgewählte Assets",
        "successNotificationText": "Die Assets wurden aktualisiert.",
        "successNotificationTitle": "Erfolgreich!",
        "type": "Typ",
        "usersEditingAccess": "Benutzer mit Schreibzugriff",
        "usersViewingAccess": "Benutzer mit Lesezugriff",
        "warning": "Warnung."
    },
    "fr": {
        "color": "Couleur",
        "disclaimer": "Les valeurs existantes seront écrasées.",
        "heading": "Editer",
        "location": "Emplacement",
        "saveDetails": "Sauvegarder les détails",
        "savePermissions": "Sauvegarder les permissions",
        "selectedAssets": "Assets sélectionnés",
        "successNotificationText": "Les assets ont été mis à jour.",
        "successNotificationTitle": "Succès !",
        "type": "Type",
        "usersEditingAccess": "Utilisateurs avec accès à l'écriture",
        "usersViewingAccess": "Utilisateurs avec accès à la lecture",
        "warning": "Attention."
    },
    "it": {
        "color": "Colore",
        "disclaimer": "I vecchi valori saranno sovrascritti.",
        "heading": "Modificare",
        "location": "Locazione",
        "saveDetails": "Salvare i dettagli",
        "savePermissions": "Salvare i permessi",
        "selectedAssets": "Assets seleczionati",
        "successNotificationText": "Le attività sono state aggiornate.",
        "successNotificationTitle": "Successo!",
        "type": "Typo",
        "usersEditingAccess": "Utente con accesso in scrittura",
        "usersViewingAccess": "Utente con accesso in letturas",
        "warning": "Attenzione."
    }
}
</i18n>

<style lang="scss" scoped>
label {
    font-size: $fs-small;
}

a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.heading {
    padding-bottom: 0;
}

.type-card-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    padding-top: 8px;

    div {
        margin: 8px 16px 8px 0;
    }
}

.disclaimer {
    text-align: center;
    font-size: $fs-small;
}
</style>
